import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/blJsU31K88w"
    notesSrc="http://bible.com/events/7187252"
    notesText="Sermon Notes"
    bibleGroupSrc="https://www.docdroid.net/BDxfNE1/bible-group-homework-4-12-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="When Pigs Fly - The Miracle of the Resurrection" />
  </Layout>
)

export default SermonPost
